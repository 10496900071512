import React from 'react'
import { Box } from '@mui/material'
import "./map.css"


const MapHeader = () => {
  return (
    <Box sx={{width: {xs: 125, sm: 170, md: 200, lg: 200}, mx: "auto", opacity: .87}}>
        <svg viewBox="0 0 147 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0261 1.92C13.0261 1.92 4.92609 21.66 4.92609 25.02C4.92609 25.32 4.98609 25.5 5.16609 25.5C5.22609 25.5 5.34609 25.5 5.46609 25.38C14.9461 13.32 19.8061 1.02 25.6261 1.02C26.3461 1.02 27.0661 1.2 27.8461 1.56C28.8661 2.4 29.2861 3.36 29.2861 4.56C29.2861 6.9 27.7861 10.14 25.9861 15.66C24.9661 17.76 23.3461 20.64 23.3461 21.12L23.4061 21.18C24.1261 21.18 30.2461 13.08 33.9661 13.08H34.2661C38.8261 14.82 36.1261 23.82 41.2861 23.82C41.5861 23.82 41.8261 23.82 42.1861 23.76C45.1861 23.28 53.2261 17.4 57.7261 11.4C58.0261 10.92 58.4461 10.74 58.9261 10.74C59.8261 10.74 60.7861 11.52 60.7861 12.18C60.7861 12.36 60.7261 12.48 60.6661 12.6C54.5461 19.14 45.6661 24.18 42.2461 24.72C41.8261 24.78 41.4661 24.84 41.1061 24.84C35.1661 24.84 35.4661 15.9 33.6661 15.9C33.5461 15.9 33.4861 15.96 33.3661 16.02C29.1661 18.24 24.4861 26.58 21.9061 26.88C21.5461 26.94 21.1261 26.94 20.7661 26.94C19.1461 26.94 17.5861 26.4 17.5861 24.6C17.5861 24.24 17.6461 23.88 17.7661 23.46C17.7661 23.4 25.2061 7.92 25.2061 3.6C25.2061 3.12 25.1461 2.82 24.9661 2.64H24.7261C20.5261 2.64 9.90609 27.48 3.60609 30.42C1.50609 30.36 0.126091 30.12 0.126091 27.12C0.126091 25.98 0.306091 24.48 0.786091 22.44C3.00609 13.56 6.36609 3.72 7.20609 1.98C7.38609 0.839997 9.06609 0.239998 10.5661 0.239998C11.8861 0.239998 13.0861 0.659998 13.0861 1.56C13.0861 1.68 13.0261 1.8 13.0261 1.92Z" fill="black" class="map-head-1"></path>
        <path d="M71.1169 12.12C70.2769 12.96 69.9169 13.26 68.8969 14.22C64.1569 18.72 58.7569 23.76 56.1769 23.76C56.1169 23.76 55.9969 23.7 55.8769 23.7C55.2169 23.58 54.7369 23.22 54.3169 22.74C53.8369 22.14 53.6569 21.42 53.6569 20.58C53.6569 20.28 53.6569 19.98 53.7169 19.68C53.8369 18.72 54.1969 17.52 54.7969 16.32C56.5369 12.48 60.2569 7.98 64.8769 6.6C66.0769 6.24 67.1569 6.12 68.1169 6.12C69.9769 6.12 71.3569 6.72 72.2569 7.62C74.0569 6.12 76.1569 4.62 77.2969 4.62C77.4769 4.62 77.6569 4.68 77.7769 4.74C78.5569 4.86 78.9769 5.64 78.9769 6.96C78.9769 8.4 78.4969 10.44 77.4769 13.02C76.8169 14.82 76.5169 16.32 76.5169 17.52C76.5169 20.64 78.4969 21.78 81.4369 21.78C89.0569 21.78 103.217 13.74 105.317 11.1C105.677 10.38 106.097 10.14 106.457 10.14C106.997 10.14 107.357 10.62 107.357 11.28C107.357 11.64 107.237 12 106.937 12.36C106.217 13.38 90.1369 22.8 80.7769 22.8C76.9369 22.8 74.2369 21.18 74.2369 16.68C74.2369 15.48 74.4169 14.1 74.8369 12.42C75.1969 10.14 75.9769 8.04 75.9769 7.56V7.5C75.9769 7.5 75.0769 8.34 73.6969 9.66C73.9369 10.38 73.9369 11.1 73.9369 11.46V11.7C73.3969 12.48 72.6769 12.84 72.0169 12.84C71.7769 12.84 71.5369 12.78 71.2969 12.66L71.1169 12.12ZM68.8369 10.56C68.5369 10.32 68.2369 10.26 67.8769 10.26C64.9969 10.26 59.9569 16.8 59.9569 18.18C59.9569 18.36 60.0769 18.48 60.3169 18.48C61.4569 18.48 65.0569 15.6 68.8369 10.56Z" fill="black" class="map-head-2"></path>
        <path d="M111.273 2.16L104.013 27.78C105.993 23.4 113.493 2.82 123.273 2.82C124.173 2.82 125.133 3 126.093 3.36C128.373 4.98 129.273 8.34 129.273 11.28C129.273 12.12 129.153 13.02 129.033 13.74C134.133 12.12 139.113 10.86 144.093 10.86C144.753 10.86 145.413 10.86 146.133 10.92C146.673 10.98 146.853 11.4 146.853 11.88C146.853 12.66 146.253 13.62 145.533 13.62H145.413C143.793 13.44 142.233 13.32 140.673 13.32C136.833 13.32 133.113 13.98 128.913 15.48C126.873 21.36 121.413 28.62 114.573 29.34C114.153 29.4 113.793 29.4 113.493 29.4C111.813 29.4 110.913 28.92 110.253 28.38C109.653 27.96 109.413 27.42 109.413 26.88C109.413 22.74 124.353 15.78 126.153 14.76C126.213 14.64 126.273 14.46 126.333 14.34C126.393 13.92 126.453 13.38 126.453 12.78C126.453 10.44 125.793 7.02 124.413 6.06C123.453 5.28 122.433 4.92 121.473 4.92C110.733 4.92 99.2733 48.3 96.2133 60.84C96.1533 61.02 96.1533 61.26 96.1533 61.5C96.1533 62.04 96.2133 62.76 96.2133 63.24V63.6C96.1533 63.84 95.5533 64.02 94.9533 64.02C94.1733 64.02 93.2133 63.72 93.2133 62.82C93.2133 62.7 93.2133 62.58 93.2733 62.46C97.3533 42.24 101.493 27 107.013 6.54L106.833 2.64C107.013 1.2 108.213 0.599997 109.293 0.599997C110.313 0.599997 111.273 1.14 111.273 1.92V2.16ZM113.553 27.66C118.713 27.18 123.393 21.36 125.433 16.8C120.213 18.66 110.973 24.66 110.973 26.64C110.973 26.82 111.093 26.94 111.213 27.06C111.513 27.3 111.693 27.72 112.833 27.72C113.073 27.72 113.253 27.66 113.553 27.66Z" fill="black" class="map-head-3"></path>
        </svg>
    </Box>  
    )
}

export default MapHeader